.unauthorizedContainer {
  width: 100vw;
  height: 100%;
  background: var(--tia-color-body-bg, #fafbfe);
  display: flex;
  justify-content: center;

  .card {
    margin-top: 20%;
    width: 300px;
    height: 280px;
    border: 1px solid var(--tia-color-cmp-border, #e2e5ea);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
    padding: 20px;
    background: var(--tia-panel-bg, #ffffff);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;

    .icon {
      background: var(--tia-unauthorized-icon-url, url(../../resources/media/unauthorized-icon.png));
      height: 136px;
      width: 140px;
    }

    .errorText {
      font-size: 14px;
      width: 226px;
      text-align: center;
      color: var(--tia-color-body-text, #405e6c);
    }

    .buttonContainer {
      width: 100%;
    }
  }
}

.copyright {
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  bottom: 20px;
  color: var(--tia-color-caption-text, #929aa8);
}

